<template lang="pug">
  .saving-status-notice
    .notice(v-if="isUnsavedChanges")
      template(v-if="isCollapsed")
        FaIcon.m-r-5(
          spin
          icon="spinner"
        )
        span {{ $t("inventory_management.inventory_for_future.saving") }}
      .to-save(v-else)
        FaIcon.m-r-5(icon="info-circle")
        | {{ $t("inventory_management.inventory_for_future.collapse_to_save") }}
    .notice(v-else-if="isNewSetting")
    .notice(v-else)
      | {{ $t("inventory_management.inventory_for_future.saved") }}
</template>

<script>
  export default {
    props: {
      isUnsavedChanges: Boolean,
      isNewSetting: Boolean,
      isCollapsed: Boolean
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .notice
    color: $default-gray
    font-size: 0.8rem
    margin-right: 15px

    .to-save
      color: $default-red
</style>
